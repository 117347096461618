@import './../../../../styles/abstracts.scss';

.wrapper {
  @include cardAuth;
  background-color: colors("pebble");
  width: 55%;
  min-height: 512px;
  height: 65vh;
  display: flex;
  flex-direction: column;
  padding: 20px 32px 22px 32px;
  justify-content: space-between;

  @media (max-width: $medium) {
    width: 100%;
  }

  .headerTitle {
    .activitiesTitle {
      @include P1;
      color: colors("moss");
    }

    .dateTitle {
      @include subtitle;
      color: colors("moss");
      font-family: 'Matter Medium';
      margin-bottom: 24px;
    }
  }

  .activitiesWrapper {
    .tabs {
      @include P2;
      color: colors("moss");
    }

    .listWrapper {
      display: flex;
      flex-direction: column;
      height: 300px;
      overflow-x: hidden;
      overflow-y: auto;
      margin-right: -70px;
      padding-right: 70px;

      @media (max-width: $medium) {
        margin-right: 0;
        padding-right: 0;
        padding-top: 25px;
        // overflow-y: hidden;
      }

      .activities {
        position: relative;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding-top: 9px;
        padding-bottom: 10px;
        width: calc(100% + 70px);

        @media (max-width: $medium) {
          width: 100%;
        }

        .wrapperEditActivity {
          display: flex;
          flex-direction: row;
          align-items: center;
          padding: 0;
          gap: 0px;

          .wrapperNameTime {
            display: flex;

            flex-direction: column;
            gap: 2px;

            @media (max-width: $medium) {
              margin-left: 5px;
            }

            .activityName {
              @include P1;
              color: colors("moss");
            }

            .activityTime {
              @include P2;
              color: colors("moss");
            }
          }

          .wrapperMore {
            display: flex;
            flex-direction: row;

            .wrapperIconButtonsResponsive {
              position: absolute;
              left: 5vw;
              display: flex;
              flex-direction: row;
              border-radius: 23px;
              background: colors('aqua');
              padding: 0px 5px;

              .actionButton {
                padding-right: 5px;
              }
            }
          }

          .wrapperIconButtons {
            display: flex;
            flex-direction: row;
            gap: 5px;

            .editButton {
              display: none;
              padding: 0;
            }
          }

          &:hover {
            gap: 5px;

            .editButton {
              display: flex;
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 11px;

          @media (max-width: $medium) {
            width: 142px;
            gap: 15px;
          }

          .buttonTag {
            @include P2;
            color: colors('moss');
            width: 100px;
            height: 17px;
            border: 0.5px solid colors('moss');
            border-radius: 2px;
            padding: 0px;

            @media (max-width: $medium) {
              height: 28px;
            }
          }

          .feelingWrapperResponsive {
            position: absolute;
            right: 0;
            top: -3vh;
            display: none;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
            background: colors('aqua');
            border-radius: 12px;
            width: 100px;
            height: 32px;
          }

          .feeling {
            width: 23px;
            height: 23px;
            border: 1px solid colors('moss');
            border-radius: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .feelingWrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding-left: 5px;
            padding-right: 5px;
            background-color: transparent;
            border-radius: 12px;
            width: 95px;
            height: 28px;

            .feeling {
              width: 17px;
              height: 17px;
              border: 1px solid colors('moss');
              border-radius: 100%;
              display: flex;
              justify-content: center;
              align-items: center;
            }

            .feelingOptions {
              display: none;
              flex-direction: row;
              justify-content: center;
              align-items: center;
              gap: 2px;
            }

            &:hover {
              background: colors('aqua');

              .feelingOptions {
                display: flex;
              }
            }
          }
        }
      }
    }

    .listWrapper::-webkit-scrollbar {
      display: none;
    }
  }

  .addActivityWrapper {
    display: flex;
    border: 1px solid colors('moss');
    height: 35px;
    .newActivityButton {
      &:hover {
        background: transparent;
      }
    }

    .newActivityButton p {
      @include P1;
      text-transform: capitalize;
      color: colors('moss');
    }
  }

  .addActivityWrapperAqua {
    display: flex;
    border: 1px solid colors('moss');
    height: 35px;

    .addActivity {
      display: flex;
      width: 100%;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0px 10px;

      .addActivityName {
        @include P1;
        color: colors('moss');
        height: 25;
        width: 65%;
      }

      .timeCheckWrapper {
        display: flex;
        gap: 15px;

        .time {
          @include P1;
          color: colors('moss');
          padding: 0px 5px;
          min-width: 80px;
          height: 25px;
          background: colors('pebble');
          border: 1px solid colors('moss');
          border-radius: 2px;

          &:hover {
            border: '1px solid #f7f1d9';
            border-radius: '2px';
          }
        }
      }
    }
  }
}

.modalBox {
  @include card;
  @include flex;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: colors('dark');
  padding: 36px 36px;

  .modalBody {
    .modalTitle {
      @include font('Matter', 24, 26, 700);
      display: flex;
      text-align: -webkit-center;
      color: colors('pebble');
      margin-bottom: 30px;
    }

    .modalButtons {
      @include flex;
      flex-direction: row;
      gap: 30px;
    }

    .modalButtons Button {
      @include button;
      background-color: colors('pebble');
      width: 88px;
      margin-top: 26px;
    }

    .modalButtons Button p {
      @include font('Matter', 12, 16, 400);
      text-transform: lowercase;
      color: colors('moss');
    }

    .modalButtons Button p::first-letter {
      text-transform: uppercase;
    }
  }
}
