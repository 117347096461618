@import './../../../../styles/abstracts.scss';

.wrapper {
  @include cardAuth;
  background: colors("pebble");
  width: 45%;
  min-height: 512px;
  height: 65vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $medium) {
    width: 100%;
    min-height: 200px;
    height: min-content;
  }

  .wrapperTop {
    margin: 21px 14px 0px 14px;
    height: 30%;
    .wrapperTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 18px 30px 18px;

      .dailyTitle {
        @include P1;
        color: colors("moss");
      }
    }
  }

  .wrapperBottom {
    position: relative;
    margin: 38px 32px 32px 32px;

    .headerResponsive {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;

      .dailyTitleResponsive {
        @include P1;
        color: colors("moss");
      }

      .addIconButtonResponsive {
        background-color: colors('aqua');
        width: 20px;
        height: 20px;
      }
    }

    .dailyTitle {
      @include P1;
      margin-bottom: 30px;
      color: colors("moss");
    }

    .wrapperIconButton {
      position: absolute;
      height: 70%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: -34px;

      .addIconButton {
        background-color: colors('aqua');
        width: 20px;
        height: 20px;
      }
    }

    .wrapperJournal {
      background-color: white;
      max-height: 256px;
      overflow: scroll;
      padding: 30px 25px;
      border-radius: 12px;

      @media (max-width: $medium) {
        // overflow: hidden;
      }

      .dailyLine {
        height: 50px;

        .containerText {
          margin-top: 6px;
        }

        .hour {
          @include P2;
          color: colors("moss");
          background-color: transparent;
          font-weight: 500 !important;
        }

        .editButton {
          padding-top: 2px;
        }

        .text {
          @include P1;
          color: colors('moss');
          background-color: transparent;
        }
      }
    }
  }
}

.modalCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50vh;
  margin-left: 50vw;
  transform: translate(-50%, -50%);
  width: 448px;
  height: 340px;
  border-radius: 12px;
  padding: 15px 15px;
  background-color: colors('pebble');

  @media (max-width: $medium) {
    width: 345px;
    height: 335px;
  }

  .modalBox {
    border-radius: 12px;
    padding: 0px 15px;
    margin-bottom: 20px;
    width: 418px;
    height: 287px;
    background-color: white;
    overflow-y: auto;

    @media (max-width: $medium) {
      width: 100%;
      height: 335px;
    }
  }

  .buttonsModalWrapper {
    display: flex;
    justify-content: space-between;
    gap: 30px;
    .modalButton {
      @include button;
      background: colors("aqua");
      width: max-content;
    }

    .modalButton p {
      @include P1;
      text-transform: lowercase;
      color: colors('moss');
    }

    .modalButton p::first-letter {
      text-transform: uppercase;
    }
  }
}

.modalCalendarCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50vh;
  margin-left: 50vw;
  transform: translate(-50%, -50%);
  width: 448px;
  border-radius: 12px;
  padding: 15px 15px;
  background-color: colors('moss');

  @media (max-width: $medium) {
    width: 345px;
    height: 335px;
  }
}

.wrapperJournal::-webkit-scrollbar {
  display: none;
}
