@import './../../../styles/abstracts.scss';

$imagen-ruta: '#{$image-path}backgroun-activities.png';

.activities {
  @include backgroundAuth;
  align-items: center;
  background-image: url($imagen-ruta);
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  .container {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media (min-width: $medium) {
      width: 893px;
    }

    .title {
      @include title;
      color: colors('moss');
      font-family: 'Eksell Display';
      margin-top: 20px;

      @media (max-width: $medium) {
        margin-top: 70px;
      }
    }

    .wrapperSubtitle {
      display: flex;
      margin-top: 64px;

      @media (max-width: $medium) {
        margin-top: 24px;
      }

      .subtitle {
        @include font('Matter Medium', 21, 23, 500);
        color: colors('moss');
      }

      .subtitle2 {
        @include subtitle;
        color: colors('moss');
      }
    }
    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 32px;
      margin-top: 22px;

      @media (max-width: $medium) {
        flex-direction: column;
        gap: 24px;
      }
    }
  }
}
