@import './../../../styles/abstracts.scss';

.backButton {
  &:hover {
    background-color: transparent;
  }
}

.backButton p {
  @include P3;
  text-transform: lowercase;
  color: colors('moss');
}

.backButton p::first-letter {
  text-transform: uppercase;
}

.teamName {
  @include flex;
  @include subtitle;
  color: colors('moss');
  padding: 10px 14px;
  border-radius: 6px;
}
