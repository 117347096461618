@import './../../../styles/abstracts.scss';

.wrapperButtons {
  display: flex;
  flex-direction: column;
  gap: 10px;

  .buttons {
    @include flex;
    gap: 10px;

    .bulkButton {
      &:hover {
        background-color: transparent;
      }
    }

    .bulkButton p {
      @include font('Matter', 12, 16, 400);
      text-transform: lowercase;
      color: colors('aqua');
    }

    .bulkButton p::first-letter {
      text-transform: uppercase;
    }
  }

  .errorMessage {
    display: flex;
    width: 280px;
  }

  .errorMessage h4 {
    @include font('Matter', 12, 16, 400);
    color: colors('rose');
  }
}

.sendEmail {
  display: flex;
  width: 100%;
  height: min-content;
  padding-top: 10px;

  .sendEmailButton {
    @include button;
    background-color: colors('aqua');
    width: 150px;

    &:disabled {
      opacity: 0.6;
    }
  }

  .sendEmailButton p {
    @include font('Matter', 12, 16, 400);
    text-transform: lowercase;
    color: colors('pebble');
  }

  .sendEmailButton p::first-letter {
    text-transform: uppercase;
  }
}
