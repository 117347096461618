@import './../../../../styles/abstracts.scss';

.wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 13px;

    .wrapperIcon {
      display: flex;
      align-items: center;
      gap: 5px;

      .feeling {
        @include P1;
        color: colors("moss");
      }
    }
    .wrapperPercentage {
      display: flex;
      align-items: center;
      justify-content: space-around;
      width: 33%;
      height: 13px;
      border-radius: 2px;

      @media (max-width: $medium) {
        width: 65px;
        height: 28px;
      }

      .percentage {
        @include caption;
        mix-blend-mode: multiply;
        line-height: 4px;
        color: colors('moss');

        @media (max-width: $medium) {
          @include P1;
          mix-blend-mode: multiply;
          line-height: 4px;
          color: colors('moss');
        }
      }
    }
  }

  .middle {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    margin-bottom: 6px;
    .totalFeeling {
      @include subtitle;
      color: colors("moss");
    }
    .filter {
      @include P2;
      color: colors("moss");

      @media (max-width: $medium) {
        @include P1;
        color: colors("moss");
      }
    }
  }

  .bottom {
    display: flex;
    flex-direction: column;
    margin-top: 13px;
    height: 90px;
    overflow-y: scroll;

    @media (max-width: $medium) {
      height: 150px;
      overflow-y: hidden;
    }

    @media (max-width: $medium) {
      gap: 5px;
    }
    .numberFeel {
      @include P2;
      color: colors("moss");
      margin-top: 2px;
      margin-bottom: 5px;
    }
  }
}

.bottom::-webkit-scrollbar-thumb {
  border: 1px solid colors('moss');
}

.bottom::-webkit-scrollbar {
  width: 1px;
}
