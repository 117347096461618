@import '../../../../styles/abstracts.scss';

.table {
  width: 100%;
  overflow-y: auto;
  scrollbar-width: thin;
  min-width: 650px;
  max-height: 440px;

  .name {
    display: flex;
    flex-direction: column;

    .email {
      @include font('Matter', 8, 12, 400);
    }
  }
}
