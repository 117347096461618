@mixin font($family: 'Matter', $size: 14, $line: 18, $weight: 500) {
  $baseFont: 20;
  $fontSize: #{$size}px;
  $lineHeight: #{$line}px;
  $fontFamily: #{$family};

  font-family: $fontFamily;
  font-size: $fontSize;
  font-size: calculateRem($size, $baseFont) !important;
  line-height: $lineHeight;
  line-height: calculateRem($line, $baseFont) !important;
  font-weight: $weight !important;
}

@mixin from-breakpoint($bp) {
  @if ($bp == 'x-small') {
    @content;
  }
  @if ($bp == 'ie-only') {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      @content;
    }
  } @else {
    @if map-has-key($breakpoints, $bp) {
      $list: map-get($breakpoints, $bp);
      $bp: nth($list, 1);
    }

    @media screen and (min-width: #{$bp}) {
      @content;
    }
  }
}

@mixin loadingPage {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
}

@mixin card {
  position: absolute;
  width: 479px;
  background-color: colors('moss');
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  z-index: zindex('overlay');

  @media (max-width: $medium) {
    width: 329px;
    min-height: 303px;
  }
}

@mixin button {
  display: flex;
  height: 25px;
  padding: 4px 12px;
  background: colors('pebble');
  border-radius: 12px;
}

@mixin responsiveButton {
  display: flex;
  justify-content: flex-start;
  padding: 0px 36.69px;
  width: 100%;
  height: 46px;
  border-radius: 23px;
  background: rgba(26, 49, 39, 0.5);
}

@mixin input {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 13px;
  background: colors('aqua');
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  color: colors('pebble');
}

@mixin select {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 8px 13px;
  width: 150px;
  height: 14px;
  background: colors('moss');
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
  border-radius: 4px;
}

@mixin flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin background {
  background-color: colors('moss');
  min-height: calc(100vh - 40px);
  height: 100vh;
  padding: 16px 16px;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: space-around;
}

@mixin backgroundAuth {
  background-color: colors('white');
  width: 100%;
  min-height: 100vh;
  padding: 40px 70px 20px 70px;
  display: flex;
  flex-flow: column;

  @media (max-width: $medium) {
    padding: 30px 16px 20px 15px;
  }
}

@mixin adminBackground {
  min-height: calc(100vh - 100px);
  background: colors('pebble');
  padding: 20px 70px 20px 70px;
}

@mixin cardAuth {
  background-color: colors('moss');
  border-radius: 12px;
  z-index: zindex('overlay');
}

@mixin title {
  @include font('Matter', 33, 40, 400);
  color: colors('greyedOut');
}

@mixin subtitle {
  @include font('Matter', 21, 23, 500);
  color: colors('greyedOut');
}

@mixin caption {
  @include font('Matter', 10, 12, 400);
  color: colors('greyedOut');
}

@mixin H1 {
  @include font('Eksell Display', 40, 44, 500);
  color: colors('greyedOut');
}

@mixin H2 {
  @include font('Eksell Display', 33, 40, 500);
  color: colors('greyedOut');
}

@mixin H3 {
  @include font('Matter Medium', 21, 23, 500);
  color: colors('greyedOut');
}

@mixin H4 {
  @include font('Matter', 21, 23, 400);
  color: colors('greyedOut');
}

@mixin P1 {
  @include font('Matter', 13, 12, 400);
  color: colors('greyedOut');
}

@mixin P2 {
  @include font('Matter', 12, 9, 400);
  color: colors('greyedOut');
}

@mixin P3 {
  @include font('Matter', 15, 16, 400);
  color: colors('greyedOut');
}

@mixin modalCard {
  @include card;
  @include flex;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 650px;
  height: 400px;
  background-color: colors('pebble');
  padding: 66px 66px;
}
