@import './../../../styles/abstracts.scss';

$imagen-ruta: '#{$image-path}background-personal.png';

.personalAnalytics {
  @include backgroundAuth;
  align-items: center;
  background-image: url($imagen-ruta);
  background-repeat: no-repeat;
  background-size: cover;

  .container {
    display: flex;
    flex-direction: column;

    @media (min-width: $medium) {
      width: 893px;
    }

    .wrapperTitle {
      display: flex;
      align-items: baseline;
      justify-content: space-between;

      .title {
        @include title;
        color: colors('moss');
        font-family: 'Eksell Display';
        margin-top: 20px;

        @media (max-width: $medium) {
          margin-top: 70px;
        }
      }
    }

    .wrapperSubtitle {
      display: flex;
      margin-top: 64px;

      @media (max-width: $medium) {
        margin-top: 24px;
      }

      .subtitle {
        @include font('Matter Medium', 21, 23, 500);
        color: colors('moss');
      }

      .subtitle2 {
        @include subtitle;
        color: colors('moss');
      }
    }

    .tabsResponsive {
      display: flex;
      max-width: 100%;
      margin-top: 24px;
      overflow-y: auto;
    }

    .tabsResponsive::-webkit-scrollbar {
      display: none;
    }

    .row {
      display: flex;
      align-items: center;
      justify-content: space-between;

      gap: 32px;
      margin-top: 22px;

      @media (max-width: $medium) {
        flex-direction: column;
        width: 100%;
      }

      .colLeft {
        display: flex;
        flex-direction: column;
        width: 65%;
        gap: 28px;
        min-height: 512px;
        height: 65vh;

        @media (max-width: $medium) {
          width: 100%;
          height: 100%;
        }

        .rowTop {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 32px;
          min-height: 200px;
          height: 30%;

          @media (max-width: $medium) {
            flex-direction: column;
            gap: 20px;
            min-height: 100%;
            height: 100%;
          }

          .colLeftTop {
            @include cardAuth;
            background: colors('pebble');
            width: 33%;
            padding: 21px 21px 17px 21px;

            @media (max-width: $medium) {
              width: 100%;
              height: 300px;
            }
          }
        }

        .colLeftBottom {
          @include cardAuth;
          background: colors('pebble');
          width: 100%;
          min-height: 288px;
          height: 70%;

          @media (max-width: $medium) {
            min-height: 100%;
            height: 100%;
          }
        }
      }
      .colRight {
        display: flex;
        width: 35%;
        min-height: 512px;
        height: 65vh;

        @media (max-width: $medium) {
          width: 100%;
        }
      }
    }
  }
}
