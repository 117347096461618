@import './../styles/abstracts.scss';

.App {
  min-height: 100vh;
  height: max-content;
  position: relative;
  background-image: none;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  overflow-y: auto;
  display: flex;
  z-index: zindex('base');

  .appLogo,
  .profileLogo {
    cursor: pointer;
    position: absolute;
    display: none;
    top: 16px;
    z-index: zindex('overlay');
  }

  .appLogo {
    left: 16px;
  }

  .profileLogo {
    right: 16px;
  }

  .authMain {
    min-width: 100%;
    min-height: calc(100vh - 100px);
  }

  .main {
    min-height: 100vh;
    width: 100vw;
  }

  @include from-breakpoint('small') {
  }

  @include from-breakpoint('medium') {
    .main {
      margin: 0 auto;
    }
  }

  @include from-breakpoint('x-large') {
    .appLogo,
    .profileLogo {
      display: block;
      top: 48px;
      width: 110px;
    }

    .appLogo {
      left: 28px;
    }

    .profileLogo {
      right: 28px;
    }

    .main {
      .appLogo,
      .profileLogo {
        display: none;
      }
    }
  }
}
