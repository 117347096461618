$red: #ce2828;
$greyedOut: #f7f1d9;
$dark: #14271f;
$deep: #070c0a;
$cacti: #688c83;
$thistle: #b9a1e1;
$aqua: #4ea7ac;
$sage: #9fb8a1;
$daisy: #f3ed6c;
$rose: #ff9275;
$moss: #1a3127;
$pebble: #f7f7f7;
$dark-2: #374b42;
$dark-3: #889890;
$white: #ffff;
$gray: #d3d3d3;
