@import './../../../styles/abstracts.scss';

.Home {
  @include background;
  justify-content: center;
  gap: 20px;

  .logo {
    position: absolute;
    top: 56px;
    left: 40px;
  }

  .greeting {
    @include title;
    color: colors("pebble");
    font-family: 'Eksell Display';
    width: 479px;
    margin-bottom: 50px;
    text-align: center;

    @media (max-width: $medium) {
      width: 329px;
      margin-bottom: 17px;
    }
  }

  .card {
    @include card;
    background-color: colors("pebble");
    position: relative;
    min-height: 339px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapperLogin {
      display: flex;
      flex-direction: column;
      margin-top: 80px;

      .customTextField {
        background-color: white;
        color: colors("moss");
        & input::placeholder {
          color: colors("moss");
        }
        & fieldset {
          border-color: white;
          &:hover {
            border-color: white;
          }
        }
        &:focus-within {
          & fieldset {
            border-color: white;
          }
        }
      }
    }

    .progress {
      @include flex;
      margin-top: 26px;
    }

    .loginButtonWrapper {
      @include flex;

      .loginButton {
        @include button;
        background: colors("aqua");
        width: 49px;
        margin-top: 26px;
      }

      .loginButton p {
        @include P1;
        text-transform: capitalize;
        color: colors('moss');
      }
    }

    .title {
      margin-bottom: 26px;
      @include font('Matter', 21, 23, 700);
      color: colors('greyedOut');
    }

    .errorMessage h4 {
      @include P1;
      color: colors('rose');
    }

    .errorMessage {
      @include flex;
      flex-direction: column;
      width: 340px;
      margin-top: 26px;
      margin-bottom: 26px;
      gap: 10px;

      @media (max-width: $medium) {
        width: 265px;
      }
    }

    .forgotButton p {
      @include P1;
      text-transform: lowercase;
      color: colors('moss');
      text-decoration: underline;
    }

    .forgotButton p::first-letter {
      text-transform: uppercase;
    }
  }

  .message {
    @include subtitle;
    color: white;

    position: absolute;
    bottom: 84px;
    right: 83px;

    @media (max-width: $medium) {
      width: 100%;
      bottom: 84px;
      left: 0%;
      text-align: center;
    }
  }

  .circle-responsive-icon {
    position: absolute !important;
    top: 13vh;
    right: 0vw;
  }

  .circle-icon {
    position: absolute !important;
    top: calc(50vh - 40px);
    left: calc(50vw - 400px);
    opacity: 0.3;

    @media (max-width: $medium) {
      top: 10vh;
      left: calc(50vw - 200px);
    }
  }

  .points-icon {
    position: absolute;
    top: calc(50vh + 115px);
    left: calc(50vw + 190px);
  }

  .points-responsive-icon {
    position: absolute;
    top: 82vh;
    right: 0vw;
  }

  .mua-icon {
    position: absolute;
    top: 87vh;
    left: 86vw;

    @media (max-width: $medium) {
      left: 37vw;
      top: 85vh;
    }
  }
}
