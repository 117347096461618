@font-face {
  font-family: 'Matter';
  src: local('Matter'), url('../../../public/assets/fonts/Matter-Regular.ttf');
}

@font-face {
  font-family: 'Matter Medium';
  src: local('Matter Medium'), url('../../../public/assets/fonts/Matter-Medium.ttf');
}

@font-face {
  font-family: 'Eksell Display';
  src: local('Eksell Display'), url('../../../public/assets/fonts/Eksell-Display-Medium.otf');
}

* {
  background-repeat: no-repeat;
  box-sizing: border-box;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;

  font-family: 'Matter';
  &:before,
  &:after {
    box-sizing: border-box;
  }
}

html {
  //Prevent iOS text size adjust after orientation change, without disabling user zoom.
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 16px;
  line-height: 1.2rem;
  height: 100%;

  body {
    margin: 0;
    min-height: 100vh;
    height: 100%;
    overflow-x: hidden;
    font-family: 'Matter' !important;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
      margin: 0;
    }

    h1 {
      font-size: 2em;
    }

    h2 {
      font-size: 1.5em;
    }

    h3 {
      font-size: 1.25em;
    }

    #root {
      height: 100%;
    }

    .loadingPage {
      @include loadingPage;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      transition: background-color 5000s ease-in-out 0s;
      -webkit-text-fill-color: #1a3127 !important;
    }

    scrollbar-width: thin;

    /* total width */

    ::-webkit-scrollbar {
      width: 4px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    ::-webkit-scrollbar-track:hover {
      background-color: transparent;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
      border: 4px solid #374b42;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: transparent;
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
      display: none;
    }
  }

  @include from-breakpoint('medium') {
    font-size: 18px;
  }

  @include from-breakpoint('large') {
    font-size: 20px;
  }
}
