@import '../../../../styles/abstracts.scss';

.modalBox {
  @include modalCard;

  .modalBody {
    display: flex;
    flex-direction: column;
    align-items: center;

    .subtitle {
      @include subtitle;
      color: colors('moss');
      margin-bottom: 20px;
    }

    .wrapper {
      display: flex;
      width: 400px;
      align-items: center;
      max-height: 250px;
      overflow-y: auto;

      .wrapperName {
        @include P3;
        color: colors('pebble');
        padding: 10px 14px;
        border-radius: 6px;
      }

      .role {
        @include P3;
        color: colors('moss');
      }
    }

    .wrapper::-webkit-scrollbar {
      display: none;
    }

    .modalTitle {
      @include font('Matter', 24, 26, 400);
      text-align-last: center;
      margin-bottom: 60px;
    }

    .modalInput {
      @include input;
      @include font('Matter', 12, 16, 400);
      width: 282px;
      height: 33px;
      margin-bottom: 10px;
    }

    .textField {
      @include font('Matter', 12, 16, 400);
      background: colors('aqua');
      width: 282px;
      height: 33px;
      margin-bottom: 10px;
    }

    .modalButtons {
      @include flex;
      flex-direction: row;
      gap: 30px;
      margin-top: 80px;
    }

    .modalButtons Button {
      @include button;
      background-color: colors('aqua');
      width: 88px;
    }

    .modalButtons Button p {
      @include font('Matter', 12, 16, 400);
      text-transform: lowercase;
      color: colors('pebble');
    }

    .modalButtons Button p::first-letter {
      text-transform: uppercase;
    }

    .wrapperSelect {
      display: flex;
      justify-content: center;

      .select {
        @include input;
        @include font('Matter', 12, 16, 400);
        width: 160px;
        height: 30px;
      }
    }
  }
}
