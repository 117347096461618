@import '../theme/index';

$iphone-five: 374px;
$iphone-se: 376px;
$x-small: 480px;
$small: 588px;
$medium: 768px;
$large: 1200px;

$image-path: '../../../../public/assets/images/v002_';

$breakpoints: (
  x-small: (
    null,
    $x-small - 1,
  ),
  small: (
    $x-small,
    $small - 1,
  ),
  medium: (
    $small,
    $medium - 1,
  ),
  large: (
    $medium,
    $large - 1,
  ),
  x-large: (
    $large,
    null,
  ),
);

$orientations: (
  portrait: portrait,
  landscape: landscape,
);

$colors: (
  'red': $red,
  'greyedOut': $greyedOut,
  'dark': $dark,
  'deep': $deep,
  'cacti': $cacti,
  'thistle': $thistle,
  'aqua': $aqua,
  'sage': $sage,
  'daisy': $daisy,
  'rose': $rose,
  'moss': $moss,
  'pebble': $pebble,
  'dark-3': $dark-3,
  'white': $white,
  'transparent': transparent,
  'gray': $gray,
);

$zindex: (
  'base': 1,
  'menu': 2,
  'overlay': 3,
  'modal': 4,
  'toast': 5,
  'modal-overlay': 10100,
);
