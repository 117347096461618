@import '../../../../styles/abstracts.scss';

.layout {
  display: flex;
  width: 100vw;
  min-height: 100vh;
  overflow-y: hidden;
  overflow-x: hidden;
  flex-direction: row;
  background: colors('pebble');

  @media (max-width: $medium) {
    overflow-y: auto;
    flex-direction: column;
  }

  .wrapperHeader {
    @include flex;
    margin-top: 20px;
    min-width: calc(100vw - 128px);
    height: 80px;
    padding-left: 18vw;
    padding-right: 18vw;
    justify-content: end;

    .textField {
      @include font('Matter', 12, 16, 400);
      background: colors('aqua');
      width: 228px;
      height: 32px;

      &:hover {
        color: colors('pebble');
      }
    }
  }

  .navigationResponsive {
    display: flex;
    align-items: center;
    min-width: 100vw;
    width: 100%;
    height: 55px;
    background: colors('pebble');
    z-index: zindex('toast');
    position: fixed;

    .menu {
      display: flex;
      margin: 0px 10px 0px 16px;
      z-index: 100;
    }

    .iconMUAResponsive {
      margin-top: 0.7vh;
      z-index: 100;
    }

    .circle3Responsive {
      position: absolute;
      left: 2vw;
    }
  }

  .navigation {
    @include flex;
    align-items: flex-start;
    flex-direction: column;
    min-height: 100vh;
    height: 100%;
    width: 128px;
    background: colors('pebble');
    z-index: zindex('base');

    @media (max-width: $medium) {
      min-width: 100vw;
      width: 100%;
      height: 55px !important;
    }

    .iconMUA {
      position: absolute;
      top: 45px;
      left: 32px;
      z-index: zindex('base');
    }

    .radioGroup {
      margin-left: 45px;
      z-index: zindex('base');

      .radio {
        .tooltip {
          display: flex;
          align-items: center;
          height: 34px;
          width: max-content;
          padding-left: 7px;
          background-color: transparent;
          border-radius: 9px;

          .radioButton {
            padding-left: 0px;
          }

          .tooltipName {
            display: block;
            @include font('Matter', 14, 16, 400);
            color: transparent;
            margin-right: 10px;
            min-width: max-content;
          }
        }

        &:hover {
          .tooltip {
            background: colors('aqua');

            .tooltipName {
              color: colors('moss');
            }
          }
        }
      }
    }

    .circle-icon {
      position: absolute;
      top: 20vh;
    }

    .logout {
      @include flex;
      position: absolute;
      top: 90vh;
      margin-left: 44px;
      z-index: zindex('base');
    }

    .logoutButton {
      color: colors('pebble');
    }
  }

  .wrapperChildrenAdmin {
    width: 100%;
    min-height: 100vh;
  }

  .wrapperChildren {
    display: flex;
    flex-direction: row;
    width: 100%;
    min-height: 100vh;
    justify-content: space-between;

    @media (max-width: $medium) {
      min-height: calc(100vh - 55px);
      height: calc(100vh - 55px);
      -webkit-overflow-scrolling: touch !important;
    }
  }

  .wrapperChildren::-webkit-scrollbar {
    display: none;
  }

  .wrapperProfile {
    display: flex;
    flex-direction: column;
    background-color: colors('pebble');
    min-height: 100vh;
    width: 25%;
    z-index: zindex('base');
    padding: 0px 8px;

    @media (max-width: $medium) {
      width: 100%;
      min-height: calc(100vh - 55px);
    }

    .points-icon {
      position: absolute;
      top: 15vh;
      right: 10vw;

      @media (max-width: $medium) {
        top: 7vh;
        left: 0vw;
      }
    }

    .circle-icon-3 {
      position: absolute;
      top: 10vh;
      right: 0;
    }

    .updateIcon {
      position: absolute;
      top: 1vh;
      right: 0.5vw;

      @media (max-width: $medium) {
        top: 6.5vh;
        right: 3vw;
      }
    }

    .userName {
      @include font('Eksell Display', 40, 48, 500);
      color: colors('moss');
      display: flex;
      text-align: -webkit-center;
      margin-bottom: 14px;
      z-index: zindex('base');
    }

    .companyName {
      @include P3;
      color: colors('moss');
      text-transform: uppercase;
      margin-top: 14px;
      margin-bottom: 24px;
    }

    .userEmail {
      @include P1;
      color: colors('moss');
      margin-bottom: 9px;
    }

    .wrapperUpdateButton {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 28px;

      .updateButton {
        @include button;
        height: 30px;
        background-color: colors('pebble');
      }

      .updateButton p {
        @include P1;
        text-transform: capitalize;
        color: colors('moss');
      }
    }

    .wrapperProfileEdition {
      display: flex;
      margin-top: 60px;
      flex-direction: column;
      align-items: center;
      height: '100%';
      z-index: zindex('base');
    }

    .sectionTitle {
      @include H4;
      color: colors('moss');
      align-self: center;

      @media (max-width: $medium) {
        display: flex;
        width: 300px;
        align-self: center;
      }
    }

    .calendarLabel {
      @include font('Matter', 16, 16, 400);
      color: colors('greyedOut');
      z-index: zindex('base');
    }

    .textFields {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      height: '30px';

      .fieldsLabel {
        @include font('Matter Medium', 13, 12, 400);
        color: colors('moss');
        z-index: zindex('base');
        margin-bottom: 10px;
        align-self: baseline;
      }
    }

    .saveButtonWrapper {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 5px;

      .saveButton {
        @include button;
        background-color: colors('aqua');

        @media (max-width: $medium) {
          height: 30px;
        }

        &:disabled {
          background-color: colors('gray');
          cursor: not-allowed;
          opacity: 0.6;
        }
      }

      .saveButton p {
        @include P1;
        text-transform: capitalize;
        color: colors('moss');
      }
    }

    .calendarIntegrationButtons {
      display: flex;
      flex-direction: column;
      min-width: 200px;
      width: 200px;
      margin-top: 80px;

      @media (max-width: $medium) {
        margin-top: 40px;
        min-width: 300px;
        width: 300px;
      }

      .sectionHelper {
        @include font('Matter', 11, 10, 300);
        color: colors('moss');
        margin-top: 10px;
        line-height: 15px;
        text-align: center;
      }

      .googleButton {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-top: 10px;
      }
    }
  }
}

.headerMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 88px;
  margin-left: 18px;
  margin-right: 22px;
}

.titleMenu {
  display: flex;
  margin: 28px 0px 5px 29px;
  @include font('Matter', 18, 19.69, 400);
  color: colors('Moss');
}

.buttonsWrapper {
  display: flex;
  flex-direction: column;
  padding: 0px 29px;
  z-index: 100;

  .buttonMenu {
    @include responsiveButton;
    margin-top: 32px;
  }

  .buttonMenu p {
    @include font('Matter', 17, 20, 500);
    text-transform: capitalize;
    color: colors('pebble');
  }
}

.footerMenu {
  position: absolute;
  bottom: 5vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 29px;
  gap: 35px;

  .profileButton {
    @include responsiveButton;
  }

  .profileButton p {
    @include font('Matter', 17, 20, 500);
    text-transform: capitalize;
    color: colors('pebble');
  }

  .logoutIcon {
    align-self: flex-start;
  }
}

.circleIcon {
  position: absolute;
  top: 10vh;
  right: 0vw;
}
