@import './../../../styles/abstracts.scss';

.Home {
  @include background;
  justify-content: center;
  gap: 20px;

  .logo {
    position: absolute;
    top: 56px;
    left: 40px;
  }

  .header {
    width: 479px;
    display: flex;
    flex-direction: column;
    gap: 40px;

    .welcomeTitle {
      @include H2;
      color: colors("pebble");
    }

    .helperTitle {
      @include subtitle;
      color: colors("pebble");
    }

    @media (max-width: $medium) {
      width: 329px;
    }
  }

  .card {
    @include card;
    background-color: colors("pebble");
    position: relative;
    min-height: 339px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .wrapperSetPassword {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      margin-top: 40px;
    }

    .customTextField {
      background-color: white;
      color: colors("moss");
      & input::placeholder {
        color: colors("moss");
      }
      & fieldset {
        border-color: white;
        &:hover {
          border-color: white;
        }
      }
      &:focus-within {
        & fieldset {
          border-color: white;
        }
      }
    }

    .title {
      margin-bottom: 26px;
      width: 340px;
      @include font('Matter', 12, 16, 400);
      color: colors('moss');

      @media (max-width: $medium) {
        width: 265px;
      }
    }

    .progress {
      @include flex;
      margin-top: 26px;
    }

    .createButtonWrapper {
      @include flex;

      .createButton {
        @include button;
        background: colors("aqua");
        width: 120px;
      }

      .createButton p {
        @include P1;
        text-transform: capitalize;
        color: colors('moss');
      }
    }

    .errorMessage h4 {
      @include font('Matter', 12, 16, 400);
      color: colors('rose');
    }

    .errorMessage {
      @include flex;
      flex-direction: column;
      width: 340px;
      margin-top: 26px;
      margin-bottom: 26px;

      @media (max-width: $medium) {
        width: 265px;
        text-align: -webkit-center;
      }
    }
  }

  .circle-responsive-icon {
    position: absolute !important;
    top: 13vh;
    right: 0vw;
  }

  .circle-icon {
    position: absolute !important;
    top: calc(50vh - 40px);
    left: calc(50vw - 400px);
    opacity: 0.3;

    @media (max-width: $medium) {
      top: 10vh;
      left: calc(50vw - 200px);
    }
  }

  .points-icon {
    position: absolute;
    top: calc(50vh + 115px);
    left: calc(50vw + 190px);
  }

  .points-responsive-icon {
    position: absolute;
    top: 82vh;
    right: 0vw;
  }

  .mua-icon {
    position: absolute;
    top: 87vh;
    left: 86vw;

    @media (max-width: $medium) {
      left: 37vw;
      top: 85vh;
    }
  }
}
