@import '../../../../styles/abstracts.scss';

.modalBox {
  @include card;
  @include flex;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%, -50%);
  width: 400px;
  background-color: colors('dark');
  padding: 36px 36px;

  .modalBody {
    .modalTitle {
      @include H3;
      color: colors('pebble');
      text-align-last: center;
      margin-bottom: 30px;
    }

    .subtitle {
      @include flex;
      text-align-last: center;
      text-align: justify;
    }

    .subtitle p {
      @include P1;
      color: colors('pebble');
    }

    .modalButtons {
      @include flex;
      flex-direction: row;
      gap: 30px;
    }

    .modalButtons Button {
      @include button;
      background-color: colors('pebble');
      width: 88px;
      margin-top: 26px;
    }

    .modalButtons Button p {
      @include P1;
      text-transform: capitalize;
      color: colors('moss');
    }
  }
}
