@import './../../../../styles/abstracts.scss';

.wrapper {
  @include cardAuth;
  background: colors("pebble");
  width: 35%;
  min-height: 512px;
  height: 65vh;
  display: flex;
  flex-direction: column;

  @media (max-width: $medium) {
    width: 100%;
  }

  .wrapperTop {
    margin: 21px 14px 0px 14px;
    height: 30%;

    .wrapperTitle {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 0px 18px 30px 18px;

      .title {
        @include P1;
        color: colors("moss");
        display: flex;
        align-items: center;
        height: 20px;
      }
    }
  }

  .wrapperDate {
    margin-top: 53px;
    display: flex;
    flex-direction: column;
    text-align: -webkit-center;
    align-self: center;
    gap: 20px;

    .date {
      @include H3;
      color: colors("moss");
      display: flex;
    }

    .select {
      @include select;
      padding: 8px 0px;
    }
  }

  .wrapperBottom {
    position: relative;
    margin: 0px 20px 100px 20px;
    height: 70%;
    max-height: 70%;

    .wrapperIcons {
      display: flex;
      justify-content: center;
      gap: 6%;
    }
  }
}
