@import './../../../../styles/abstracts.scss';

.wrapper {
  height: 100%;
  padding: 20px 30px 20px 30px;

  .title {
    @include P1;
    color: colors('moss');
  }

  .wrapperTitle {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title {
      @include P1;
      color: colors('moss');
    }

    .feelingOptions {
      display: flex;
      align-items: center;
      gap: 10px;
    }
  }

  .feelingGraphs {
    height: 80%;
    width: 100%;
  }

  .subtitleWrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    .date {
      @include H3;
      color: colors('moss');
    }

    .wrapperTeamFeelings {
      display: flex;
      align-items: center;
      gap: 10px;

      .select {
        @include select;
        width: 110px;
        color: colors('moss');
      }

      .feelingOptions {
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

  .wrapperSelect {
    display: flex;
    justify-content: center;
    margin-top: 11px;

    .select {
      @include select;
      width: 115px;
      height: 30px;
    }
  }

  .wrapperTeamNames {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 5px;

    .wrapperNames {
      display: flex;
      flex-direction: column;
      align-self: center;
      width: max-content;

      .teamName {
        @include P1;
        color: colors('moss');
      }

      .wrapperDivider {
        display: flex;
        flex-direction: row;
        justify-content: center;

        .divider {
          width: 100%;
          height: 1px;
          margin: 0;
        }
      }
    }
  }
}

.modalCard {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 50vh;
  margin-left: 50vw;
  transform: translate(-50%, -50%);
  width: 448px;
  height: 340px;
  border-radius: 12px;
  padding: 15px 15px;
  background-color: colors('pebble');

  @media (max-width: $medium) {
    width: 345px;
    height: 335px;
  }

  .modalBox {
    border-radius: 12px;
    padding: 0px 15px;
    margin-bottom: 20px;
    width: 418px;
    height: 287px;
    background-color: white;
    overflow-y: auto;

    @media (max-width: $medium) {
      width: 100%;
      height: 335px;
    }

    .date {
      display: flex;
      align-items: center;
      gap: 10px;
      @include P3;
      color: colors("moss");
      margin-top: 20px;
      margin-bottom: 10px;
    }

    .wrapperText {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 8px 0px;
      gap: 5px;

      .text {
        @include P1;
        color: colors("moss");
        background-color: transparent;
      }
    }
  }
}
