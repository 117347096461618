@import '../../../../styles/abstracts.scss';

.wrapper {
  display: flex;
  flex-direction: column;
  background-color: colors("moss");
  padding: 10px 10px;
  width: 380px;
  height: 230px;
  border-radius: 12px;

  @media (max-width: $medium) {
    width: 320px;
  }

  .input {
    @include input;
    @include P2;
    color: colors("moss");
    width: 149px;
    height: 29px;
    border-radius: 12px;
    margin-bottom: 10px;

    @media (max-width: $medium) {
      width: 200px;
      height: 26px;
    }
  }

  .listWrapper {
    display: flex;
    overflow-y: scroll;
    height: 150px;

    .wrapperCol {
      display: flex;
      flex-direction: column;
      align-items: start;

      .teamName {
        @include P1;
        color: colors('pebble');
        align-self: center;
      }

      .tagName {
        @include P2;
        color: colors('pebble');
        width: max-content;
        height: 15px;
        border: 0.5px solid colors('pebble');
        padding: 1px 5px;
        border-radius: 2px;
        margin-bottom: 6px;

        &:hover {
          background: transparent;
        }
      }
    }
  }

  .listWrapper::-webkit-scrollbar {
    width: 1px;
  }
}
