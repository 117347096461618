@import './../../../../styles/abstracts.scss';

.wrapperCalendar {
  .wrapperCarousel {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    .calendarIconButton {
      background-color: transparent;
      height: 24px;
      width: 24px;
    }
    .wrapperItemCalendar {
      display: flex;
      height: 80px;
      width: 20%;
      gap: 16px;
      align-items: center;
      justify-content: center;

      .buttonItemCalendar {
        height: 110px;
        gap: 16px;
        min-width: 90%;
        background-color: colors('aqua');
        border-radius: 12px;

        .buttonContainer {
          display: flex;
          align-items: center;
          flex-direction: column;
          justify-content: space-between;
          height: 80px;
        }
      }

      .feelingWrapper {
        margin-left: 5px;

        .backgroundIcon {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          height: 17px;
          width: 17px;
          border: 2.5px solid colors('moss');
          border-radius: 100%;
          margin-right: 8px;

          @media (max-width: $medium) {
            height: 23px;
            width: 23px;
            border: 1.5px solid colors('moss');
          }

          .feelingOptions {
            position: absolute;
            display: none;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 2px;
            left: -7px;
            width: 95px;
            height: 28px;
            padding-left: 30px;
            padding-right: 5px;
            border-radius: 12px;
            background: colors('aqua');

            @media (max-width: $medium) {
              top: -6vh;
              left: -16vw;
              padding: 0;
              gap: 8px;
              width: 100px;
              height: 32px;
            }
          }

          &:hover {
            z-index: 5;
            .feelingOptions {
              display: flex;
              z-index: -1;
            }
          }
        }
      }

      .numberDay {
        @include P3;
        color: colors("moss");
      }

      .day {
        @include P2;
        color: colors("moss");
      }

      .divider {
        border-color: colors('moss');
      }
    }
  }
}
