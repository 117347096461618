@import './../../../styles/abstracts.scss';

.teams {
  @include adminBackground;
  @include flex;
  align-items: start;
  padding: 0px;

  .wrapper {
    padding: 0px 46px 0px 0px;
    height: 613px;
    width: 900px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 33px;

      .input {
        @include input;
        @include font('Matter', 12, 16, 400);
        width: 228px;
        height: 32px;
      }

      .newTeamButton {
        @include button;
        background-color: colors('aqua');
        width: 120px;

        &:disabled {
          opacity: 0.6;
        }
      }

      .newTeamButton p {
        @include font('Matter', 12, 16, 400);
        text-transform: lowercase;
        color: colors('pebble');
      }

      .newTeamButton p::first-letter {
        text-transform: uppercase;
      }

      .wrapperButtons {
        display: flex;
        gap: 10px;
      }
    }
  }

  .cardsWrapper {
    display: flex;
    margin-top: 34px;
    padding: 15px 15px;

    overflow-y: auto;
    max-height: 480px;
    min-height: 480px;

    .card {
      @include card;
      margin-bottom: 5px;
      position: relative;
      flex-direction: column;
      padding: 20px 20px;
      background: colors('pebble');
      height: 348px;
      width: 263px;
      filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));

      .number {
        @include flex;
        @include font('Matter', 16, 22, 400);
        color: colors('moss');
      }

      .wrapperName {
        @include flex;
        flex-direction: row;
        gap: 5px;

        .name {
          @include flex;
          @include font('Matter', 12, 16, 400);
          color: colors('pebble');
          padding: 10px 14px;
          height: 18px;
          background: colors("thistle");
          border-radius: 6px;
        }
      }

      .newMemberButton {
        padding: 0px;
        margin-bottom: 5px;

        &:hover {
          background: transparent;
        }
      }

      .newMemberButton p {
        @include font('Matter', 12, 16, 400);
        text-transform: capitalize;
        color: colors('moss');
        text-decoration-line: underline;
      }

      .showMoreButton {
        padding: 0px;
        display: flex;
        justify-content: flex-start !important;

        &:hover {
          background: transparent;
        }
      }

      .showMoreButton p {
        @include font('Matter', 12, 16, 400);
        text-transform: capitalize;
        color: colors('moss');
        text-decoration-line: underline;
      }

      .wrapperEmployees {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .employees {
          display: flex;
          flex-direction: row;
          gap: 8px;
          margin-bottom: 10px;

          .employeesName {
            @include font('Matter', 16, 22, 400);
            color: colors('moss');

            .email {
              @include font('Matter', 12, 16, 400);
              color: colors('moss');
            }
          }
        }
      }
    }
  }
}
