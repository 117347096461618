@import './../../../styles/abstracts.scss';

.companies {
  @include adminBackground;
  @include flex;
  align-items: start;

  .cardList {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 12px;
    z-index: zindex('overlay');
    padding: 0px 46px 0px 46px;
    background-color: colors('pebble');
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.5);
    min-width: calc(64vw - 128px);
    min-height: 613px;
    width: 700px;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 33px;

      .input {
        @include input;
        @include font('Matter', 12, 16, 400);
        width: 228px;
        height: 32px;
      }

      .newClientButton {
        @include button;
        background-color: colors('aqua');
        width: 150px;

        &:disabled {
          opacity: 0.6;
        }
      }

      .newClientButton p {
        @include font('Matter', 12, 16, 400);
        text-transform: lowercase;
        color: colors('pebble');
      }

      .newClientButton p::first-letter {
        text-transform: uppercase;
      }
    }

    .tableWrapper {
      margin-top: 34px;
    }
  }
}
